<template>
    <div>
        <div class="card">
            <img src="../../assets/img/ambulance.png" alt="">
            <p class="name">白金会员卡</p>
            <p class="number">4569 5658 4548 454</p>
            <p class="time">2020.12.10-2021.2.10</p>
            <p class="price">免费</p>
        </div>

        <div class="phone">
            <p>通过手机号，可免费加入会员，加入会员即刻享受优惠</p>
            <input type="text" placeholder="请输入您的手机号">
        </div>

        <van-radio-group v-model="radio">
            <van-radio name="1">我已同意《时真会员》隐私政策</van-radio>
        </van-radio-group>

        <div class="button">
            <button @click="show = true">立即开通</button>
        </div>

        <van-overlay :show="show" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="success">
                    <img class="tip" src="../../assets/img/banner.png" alt="">
                    <img class="close" @click="show = false" src="../../assets/img/close.png" alt="">
                    <p>恭喜您</p>
                    <span>成功加入九芝堂药房会员</span>
                    <b>成功加入九芝堂药房会员</b>
                    <button @click="show = false">确定</button>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    data(){
        return{
            radio: false,
            show: false
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.van-radio { margin: 16px; color: #444444;} 
.card {
    width: 100%; height: 190px; background: url('../../assets/img/card.png') no-repeat center center; position: relative; background-size: 100%; margin-top: 10px;
    img { width: 52px; height: 20px; display: block; float: left; position: absolute; top: 20px; left: 32px;} 
    .name { font-style:italic; color: #E2F5FF; font-size: 14px; font-weight: bold; line-height: 20px; position: absolute; left: 100px; top: 20px} 
    .number { text-align: center; font-size: 24px; color: #FFFFFF; line-height: 30px; padding-top: 80px}
    .time { font-size: 12px; color: #FFFFFF; position: absolute; top: 140px; right: 48px;}  
    .price { background: linear-gradient(129deg,#ffe4bc 15%, #c18241); border-radius: 15px 0 0 15px; width: 76px; height: 30px; font-size: 14px; color: #FFFFFF; font-weight: blod; line-height: 30px; text-align: center; right: 16px; top: 30px; position: absolute}
}
.phone { width: 100%;
    p { padding: 16px; font-size: 14px; color: #444444; text-align: center;} 
    input { width: 343px; height: 44px; border: 1px solid #C5EBFF; display: block; margin: 0 auto; border-radius: 22px; text-align: center;}
    input::-webkit-input-placeholder{
        color:#C5EBFF;
    }
} 
.button button { width: 343px; height: 44px; border-radius: 10px; border: none; background: #1DAEFC; display: block; color: #fff; position: fixed; bottom: 16px; left: 16px;}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .success {
        width: 311px; height: 376px; border-radius: 8px; background: #fff; padding: 32px; position: relative;
        img.tip { width: 100px; height: 100px; margin: 24px auto; display: block;} 
        img.close { width: 16px; height: 16px; position: absolute; top: 16px; right: 16px;} 
        p { font-size: 18px; color: #444; text-align: center; font-weight: bold; line-height: 18px;} 
        span { font-size: 16px; display: block; color: #444; line-height: 16px; padding: 8px 0 16px 0; text-align: center;} 
        b { font-weight: normal; font-size: 12px; color: #C2C2C2; display: block; text-align: center;}
        button { border: none; color: #fff; background: #1DAEFC; font-size: 14px; border-radius: 10px; margin: 24px auto 0 auto; width: 151px; height: 44px; display: block;}  
    }
}
</style>